import { gql } from '@apollo/client';

export const TOGGLE_HISTORIC_NOT_APPLICABLE_RECORDS_SETTINGS = gql`
  mutation toggleHistoricNotApplicableRecordsSettings($input: FintelCheckSettingsInput!) {
    toggleHistoricNotApplicableRecordsSettings(input: $input) {
      settings {
        merchantId
        enabled
      }
    }
  }
`;
