import React, { ReactElement } from 'react';

import ToolSettingsTabItem from '../../../../ToolSettingsTabItem/ToolSettingsTabItem.component';
import en from '../../../../../locales/en.json';
import NotApplicableSettingsToggle from '../../../../NotApplicableSettingsToggle/NotApplicableSettingsToggle.component';

const NotApplicableSetting = (): ReactElement => {
  return (
    <ToolSettingsTabItem
      title={en.settings.tabs.check.items.notApplicable.title}
      description={en.settings.tabs.check.items.notApplicable.description}
    >
      <NotApplicableSettingsToggle />
    </ToolSettingsTabItem>
  );
};

export default NotApplicableSetting;
