import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { AppWrapper, Modal, PageTitle, Tabs } from 'components';
import { path } from 'utils';
import { DefaultPropsType } from 'types';

import TrackingProfilesTab from './Tabs/TrackingProfiles';
import AddProfileTab from './Modal';
import TrackingDomainsTab from './Tabs/TrackingDomains';
import { INTERNAL_PUBLISHER } from './enums';
import { useInternalPublisher } from './hooks';
import AccountInformationTab from './Tabs/AccountInformation';
import * as Styled from './styles';

type InternalPublisherProps = DefaultPropsType;

const InternalPublisher = ({ permissionsCodeList = [] }: InternalPublisherProps): JSX.Element => {
  const { hookIsReadOnlyList, ...hook } = useInternalPublisher(permissionsCodeList);
  const [isReadOnly] = hookIsReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.HeaderStyled>
        <Styled.HeaderTitleWrapper>
          <PageTitle>{path.internalPublisher.name}</PageTitle>
          <Styled.DescriptionStyled>{INTERNAL_PUBLISHER.DESCRIPTION}</Styled.DescriptionStyled>
        </Styled.HeaderTitleWrapper>

        <Styled.HeaderButtonAreaStyled>
          <Styled.ButtonStyled
            onClick={() => {
              hook.hookTrackingProfile.hookAddProfile.hookSetModal();
              hook.hookTrackingProfile.hookAddProfile.hookAddModal();
            }}
            disabled={isReadOnly}
          >
            <Styled.IconStyled icon={faPlus} />
            {INTERNAL_PUBLISHER.ADD_PROFILE}
          </Styled.ButtonStyled>
        </Styled.HeaderButtonAreaStyled>
      </Styled.HeaderStyled>

      <Tabs
        tabNames={[
          INTERNAL_PUBLISHER.TABS.ACCOUNT_INFO,
          INTERNAL_PUBLISHER.TABS.TRACKING_PROFILES,
          INTERNAL_PUBLISHER.TABS.TRACKING_DOMAINS,
        ]}
        elements={[
          <AccountInformationTab key="AccountInformationTab" />,
          <TrackingProfilesTab
            modalHook={hook.hookTrackingProfile.hookAddProfile.hookSetModal}
            isOpen={false}
            hook={hook.hookTrackingProfile}
            isReadOnly={isReadOnly}
            key="TrackingProfilesTab"
          />,
          <TrackingDomainsTab isOpen={false} hook={hook.hookTrackingDomains} key="TrackingDomainsTab" />,
        ]}
      />

      <Modal isOpen={hook.hookTrackingProfile.hookAddProfile.hookModal}>
        <AddProfileTab hook={hook.hookTrackingProfile.hookAddProfile} />
      </Modal>
    </AppWrapper>
  );
};

export default InternalPublisher;
