import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'components';
import { monthNames } from 'utils';

import * as Styled from './styles';

const PublisherCell = (publisherName: string, publisherUrl: string): JSX.Element => (
  <Styled.BoxStyled>
    <Styled.TextStyled theme="outer">{publisherName}</Styled.TextStyled>
    <Styled.TextStyled>{publisherUrl}</Styled.TextStyled>
  </Styled.BoxStyled>
);

const AmountCell = (amount: number, onClick: () => void): JSX.Element => {
  let amt = String(amount);

  if (amount < 0) {
    amt = `-$${amt.slice(1)}`;
  } else {
    amt = `$${amt}`;
  }

  return (
    <Styled.BoxStyled theme="amountCol">
      {amt}
      <Button onClick={onClick} theme="quaternary">
        <FontAwesomeIcon icon={faAngleRight} />
      </Button>
    </Styled.BoxStyled>
  );
};

export const columns: TableColumn[] = [
  {
    dataField: 'publisher',
    text: 'Publisher Name/URL',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => PublisherCell(value.companyName, value.companyUrl),
    width: '270px',
  },
  {
    dataField: 'merchant',
    text: 'Merchant',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => <div>{value.companyName}</div>,
    width: '270px',
  },
  {
    dataField: 'month',
    text: 'Period',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any, row: any) => <div>{`${monthNames[value - 1]} 1 / ${row.year}`}</div>,
    width: '16.5%',
  },
  {
    dataField: 'bonusType',
    text: 'Type',
    formatter: (val: any) => <div>{val.replace(/([a-z])([A-Z])/g, '$1 $2')}</div>,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '16.5%',
  },
  {
    dataField: 'currency',
    text: 'Currency',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '16.5%',
  },
  {
    dataField: 'bonusAmount',
    text: 'Amount',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '16.5%',
    notClickable: true,
    formatter: (
      value: any,
      row: any,
      __: any,
      ___: any,
      ____: any,
      _____: any,
      setModalInfo: (values: Record<string, any>) => void
    ) =>
      AmountCell(value, () => {
        setModalInfo(row);
      }),
  },
];
