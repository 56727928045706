import React, { useEffect, useState } from 'react';

import { LoadingDots, Pagination, Placeholder, Table } from 'components';

import { TRACKING_DETAILS } from '../../enums';

import * as Styled from './styles';
import { TRACKING_DOMAINS } from './enums';
import { columns } from './contracts';

type TrackingDomainsTabProps = {
  isOpen: boolean;
  hook: any;
  downloadButton: (show: boolean) => void;
};

const TrackingDomainsTab = ({ isOpen, hook, downloadButton }: TrackingDomainsTabProps): JSX.Element => {
  const [, setOpenState] = useState(false);
  downloadButton(true);

  useEffect(() => {
    setOpenState(isOpen);
  }, [isOpen]);

  return (
    <Styled.WrapperStyled>
      <Styled.TextStyled>{TRACKING_DOMAINS.DESCRIPTION}</Styled.TextStyled>

      {hook.hookLoading && <LoadingDots />}

      {hook.hookTrackingDomainsData.length > 0 && !hook.hookLoading && (
        <>
          <Table
            columns={columns}
            data={hook.hookTrackingDomainsData}
            isLoading={hook.hookLoading}
            onSort={hook.hookSetSortBy}
            sortColumn={hook.hookSortBy}
          />
          <Pagination
            total={hook.hookTotalPages}
            currentPage={hook.hookCurrentPage}
            onPageChange={hook.hookSetCurrentPage}
          />
        </>
      )}

      {!hook.hookLoading && hook.hookTrackingDomainsData.length === 0 && (
        <Placeholder title={TRACKING_DETAILS.NO_DOMAINS} subtitle={TRACKING_DETAILS.NO_DOMAINS_DESCRIPTION} />
      )}
    </Styled.WrapperStyled>
  );
};

export default TrackingDomainsTab;
