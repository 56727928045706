import React, { ReactElement } from 'react';

import { Toggle } from 'components';

import { useFintelCheckSettings } from '../../hooks/useFintelCheckSettings';
import { ToolSettings } from '../../types';

import { ToggleContainer } from './NotApplicableSettingsToggle.styles';

const NotApplicableSettingsToggle = (): ReactElement => {
  const {
    isNotApplicableSettingEnabled,
    isReadOnlyPermissions,
    isPageLoading,
    isToggleHistoricLoading,
    updateNotApplicableSetting,
  } = useFintelCheckSettings();

  const isToggleDisabled = isReadOnlyPermissions || isPageLoading || isToggleHistoricLoading;

  const handleToggleChanged = async (): Promise<void> => {
    await updateNotApplicableSetting(ToolSettings.ExcludeHistoricNotApplicableRecords, !isNotApplicableSettingEnabled);
  };

  return (
    <ToggleContainer>
      <Toggle checked={isNotApplicableSettingEnabled} onChange={handleToggleChanged} disabled={isToggleDisabled} />
    </ToggleContainer>
  );
};

export default NotApplicableSettingsToggle;
