import React, { ReactElement } from 'react';

import ToolSettingsDisabledPlaceholder from '../../ToolSettingsDisabledPlaceholder/ToolSettingsDisabledPlaceholder.component';
import ToolSettingsTabFooter from '../../ToolSettingsTabFooter/ToolSettingsTabFooter.component';
import { useFintelCheckSettings } from '../../../hooks/useFintelCheckSettings';

import BrandNameSetting from './components/BrandNameSetting/BrandNameSetting.component';
import FrequencySetting from './components/FrequencySetting/FrequencySetting.component';
import NotApplicableSetting from './components/NotApplicableSetting/NotApplicableSetting.component';
import { TabContainer, SettingsList } from './CheckTab.styles';

const CheckTab = (): ReactElement => {
  const { isToolSettingsEnabled } = useFintelCheckSettings();

  if (!isToolSettingsEnabled) {
    return <ToolSettingsDisabledPlaceholder />;
  }

  return (
    <TabContainer>
      <SettingsList>
        <FrequencySetting />
        <BrandNameSetting />
        <NotApplicableSetting />
      </SettingsList>
      <ToolSettingsTabFooter />
    </TabContainer>
  );
};

export default CheckTab;
