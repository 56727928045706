import React, { ReactElement } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Styled from './styles';

interface IChipProps {
  label: string;
  onClick?: (id: string) => void;
  className?: string;
  id: string;
}

export const Chip = ({ label, onClick, className, id }: IChipProps): ReactElement => (
  <Styled.WrapperStyled className={className}>
    {label}
    {onClick && (
      <Styled.XButtonStyled onClick={() => onClick(id)}>
        <FontAwesomeIcon icon={faTimes} />
      </Styled.XButtonStyled>
    )}
  </Styled.WrapperStyled>
);
